import * as t from "io-ts";
import {TypeOf} from "io-ts";
import {tsLocalDateTime} from "api-common"

export const UnPlannedMess = t.intersection([
    t.type({
        dateTime: tsLocalDateTime,
        holiday: t.boolean,
    }),
    t.partial({
        description: t.string
    })
])
export type UnPlannedMess = TypeOf<typeof UnPlannedMess>;

export const PlannedMess = t.intersection([
    UnPlannedMess,
    t.type({
        assignedMinis: t.array(t.number)
    }),
])
export type PlannedMess = TypeOf<typeof PlannedMess>;

export const MiniSchedule = t.type({
    id: t.number,
    created: tsLocalDateTime,
    description: t.string,
    schedule: t.array(PlannedMess)
})
export type MiniSchedule = TypeOf<typeof MiniSchedule>;

export const DisplayedMiniSchedule = t.type({
    id: t.number,
    created: tsLocalDateTime,
    description: t.string,
    schedule: t.array(t.intersection([
        UnPlannedMess,
        t.type({
            assignedFamilies: t.array(t.type({
                id: t.number,
                name: t.string
            }))
        })
    ])),
    friendAssignments: t.number
})
export type DisplayedMiniSchedule = TypeOf<typeof DisplayedMiniSchedule>;