import * as t from "io-ts";
import {tsLocalDateTime, tsOptional, validateApi} from "api-common";
import {Roles} from "../entities/user";
import {DisplayedMiniSchedule, MiniSchedule} from "../entities/mini-schedule";


export const miniSchedule = validateApi({
    createFromAssignmentTemplate: {
        path: "/miniSchedule/createFromAssignmentTemplate",
        params: t.type({
            maxMinisSunday: t.number,
            maxMinisSaturday: t.number,
            maxMinisHoliday: t.number,
            minMinisSunday: t.number,
            minMinisSaturday: t.number,
            minMinisHoliday: t.number,
            familyProps: t.array(t.type({
                familyId: t.number,
                includeDates: tsOptional(t.array(tsLocalDateTime)),
                excludeDates: tsOptional(t.array(tsLocalDateTime)),
                assign: tsOptional(t.boolean),
            })),
            assignmentCoordinationTemplateId: t.number,
            excludeUsers: t.array(t.number)
        }),
        queryParams: t.undefined,
        returns: t.type({
            success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR],
    },
    getById: {
        path: "/miniSchedule/getById",
        params: t.type({
            id: t.number
        }),
        queryParams: t.undefined,
        returns: DisplayedMiniSchedule,
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR],
    },
    getAll: {
        path: "/miniSchedule/getAll",
        params: t.undefined,
        queryParams: t.undefined,
        returns: t.array(MiniSchedule),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR],
    },
    delete: {
        path: "/miniSchedule/delete",
        params: t.type({
            miniScheduleId: t.number
        }),
        queryParams: t.undefined,
        returns: t.type({
           success: t.boolean
        }),
        roles: [Roles.ACTIVE, Roles.PLAN_GENERATOR],
    }
})