























































import Vue from 'vue';
import {Backend} from "api-frontend";
import {settings} from "@/settings";
import {formatDate, formatTime} from "@/helpers/format-date-time";
import {LocalDate, LocalTime} from "js-joda";
import {DisplayedMiniSchedule} from "mp-common/src/types/entities/mini-schedule";
import {miniSchedule} from "mp-common/src/types/api/miniScheduleApi";

export default Vue.extend({
  name: 'ShowCreatedPlan',
  props: {
    user: {},
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      createdPlan: {} as DisplayedMiniSchedule,
      showMiniCountDialog: false,
      highlightText: ""
    }
  },
  created: function () {
    this.fetchCreatedPlan();
  },
  methods: {
    async fetchCreatedPlan() {
      this.createdPlan = {} as DisplayedMiniSchedule;
      const result = await this.backend.query(miniSchedule.getById, {id: Number(this.$route.query.id)});
      if (result) {
        this.createdPlan = {
          ...result,
          schedule: result.schedule.sort((item, next) => item.dateTime.compareTo(next.dateTime))
        };
      }
    },
    formatDate(date: LocalDate) {
      return formatDate(date);
    },
    formatTime(time: LocalTime) {
      return formatTime(time);
    },
    async createExcel() {
      const result = await fetch(settings.backendUrl + "/miniSchedule/getExcel", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.authToken,
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(DisplayedMiniSchedule.encode(this.createdPlan)),
      });
      if (result.status === 200) {
        const blob = await result.blob();
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "MiniPlan.xlsx";
        a.click();
      }
    },
    getAssignmentCount() {
      const assignedMinis: {id: number, name: string, assignmentCount: number}[] = []
      this.createdPlan.schedule.forEach((mess) => {
        mess.assignedFamilies.forEach((family) => {
          const presentEntry = assignedMinis.find((presentFamily) => presentFamily.id === family.id);
          if (presentEntry) {
            presentEntry.assignmentCount++;
          } else {
            assignedMinis.push({
              id: family.id,
              name: family.name,
              assignmentCount: 1
            })
          }
        })
      })
      return assignedMinis;
    }
  }
})
